import React, { useState } from "react";
import { concatClassNames as cn } from "@sys42/utils";
import Layout from "../../layouts/layout";
import FormFieldSelect from "../../components/FormFieldSelect";
import * as styles from "./resources.module.css";

import imageGuerrillaUsabilityTestingCheatSheet from "../../images/guerrillaUsabilityTestingCheatSheet.jpg";
import {
  ButtonPrimaryLarge,
  Headline4Sans,
  FormFieldText,
  Headline2Sans,
} from "@userbrain/website-ui";

export default function About() {
  const title = "Guerrilla Usability Testing Cheat Sheet";

  const [formFirstName, setFormFirstName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPhoneNumber, setFormPhoneNumber] = useState("");
  const [formJobTitle, setFormJobTitle] = useState("");
  const [formCompanyName, setFormCompanyName] = useState("");
  const [formNumberOfEmployees, setFormNumberOfEmployees] = useState(null);

  return (
    <Layout
      title={title}
      metaDescription={
        "If you’re new to user testing this one’s for you. Our guerrilla usability testing cheat sheet navigates you through the most important steps of user testing by getting you to set up, test, evaluate, and improve your website."
      }
    >
      <article className={styles.splitLayout}>
        <div className={styles.splitLayoutLeft}>
          <div className={styles.splitLayoutCol}>
            <Headline2Sans className={styles.title} customTag={"h1"}>
              Guerrilla Usability Testing Cheat Sheet
            </Headline2Sans>
            <img
              className={styles.previewImage}
              src={imageGuerrillaUsabilityTestingCheatSheet}
              alt="Guerrilla Usability Testing Cheat Sheet"
            />
            <a
              className={styles.shareButton}
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                title
              )}&via=userbrain`}
            >
              Share
            </a>
          </div>
        </div>
        <div className={styles.splitLayoutRight}>
          <div className={styles.splitLayoutCol}>
            <p className={styles.intro}>
              If you’re new to user testing this one’s for you. Our guerrilla
              usability testing cheat sheet navigates you through the most
              important steps of user testing by getting you to set up, test,
              evaluate, and improve your website.
            </p>
            <Headline4Sans customTag={"h2"} className={styles.whatsInside}>
              What’s inside
            </Headline4Sans>
            <ul className={styles.unorderedList}>
              <li>
                A checklist to walk you through the process of testing and
                improving a website.
              </li>
              <li>
                The four steps to user testing: preparation/analysis, testing,
                evaluating and improving your product.
              </li>
            </ul>
            <Headline4Sans customTag={"h2"} className={styles.downloadFree}>
              Download for free
            </Headline4Sans>
            <p>
              Fill out your details below to download the {title} for free.{" "}
            </p>
            <form className={styles.form}>
              <div className={cn(styles.formRow, styles.formRow__2col)}>
                <FormFieldText
                  label={"First name"}
                  placeholder={"Enter first name"}
                  value={formFirstName}
                  onChange={setFormFirstName}
                />
                <FormFieldText
                  label={"Last name"}
                  placeholder={"Enter last name"}
                  value={formLastName}
                  onChange={setFormLastName}
                />
              </div>
              <FormFieldText
                className={styles.formRow}
                label={"Email address"}
                placeholder={"Enter email"}
                value={formEmail}
                onChange={setFormEmail}
              />
              <FormFieldText
                className={styles.formRow}
                label={"Phone number"}
                placeholder={"Enter phone number"}
                value={formPhoneNumber}
                onChange={setFormPhoneNumber}
              />
              <div className={cn(styles.formRow, styles.formRow__2col)}>
                <FormFieldText
                  label={"Job title"}
                  placeholder={"Enter job title"}
                  value={formJobTitle}
                  onChange={setFormJobTitle}
                />
                <FormFieldText
                  label={"Company name"}
                  placeholder={"Enter company name"}
                  value={formCompanyName}
                  onChange={setFormCompanyName}
                />
              </div>
              <FormFieldSelect
                className={styles.formRow}
                label={"Number of employees"}
                placeholder={"Select Number of Employees"}
                initialOption={formNumberOfEmployees}
                onChange={setFormNumberOfEmployees}
                fullWidth
                options={[
                  {
                    value: 0,
                    text: "Only me",
                  },
                  {
                    value: 1,
                    text: "2-5",
                  },
                  {
                    value: 2,
                    text: "5-10",
                  },
                  {
                    value: 3,
                    text: "10+",
                  },
                ]}
              />
            </form>
            <ButtonPrimaryLarge className={styles.formSubmit}>
              Get Cheat Sheet
            </ButtonPrimaryLarge>
          </div>
        </div>
      </article>
    </Layout>
  );
}
